export function useEventFormatter(t, asArray) {
  const generators = {
    venue: (string) => {
      if (asArray) return [`${t('event_formatter.venue')}`, `:`, `${string}`]
      return `${t('event_formatter.venue')}: ${string}`
    },

    dateTime: (date, time) => {
      let output = null
      if (!date && !time) return ''
      if (!date && time) output = time
      if (date && !time) output = date
      if (date && time) output = `${date} • ${time}`
      return asArray ? output.split(' ') : output
    },

    tickets: (tickets) => {
      if (!tickets) return ''
      let output = null
      const adult = tickets.filter((ticket) => ticket.tt_name === 'adult').length
      const child = tickets.filter((ticket) => ticket.tt_name === 'child').length

      if (adult === 0 && child === 0) return ''
      if (adult > 1 && child === 0) output = `${adult} ${t('event_formatter.adult')}`
      if (adult === 0 && child > 1) output = `${child} ${t('event_formatter.child')}`
      if (adult === 1 && child === 0) output = `${adult} ${t('event_formatter.adult')}`
      if (adult === 0 && child === 1) output = `${child} ${t('event_formatter.child')}`
      if (adult >= 1 && child >= 1)
        output = `${adult} x ${t('event_formatter.adult')}, ${child} x ${t('event_formatter.child')}`
      return asArray ? output.split(' ') : output
    },

    orderNumber: (string) => {
      return asArray
        ? [`${t('event_formatter.order_number')}`, `${string}`]
        : `${t('event_formatter.order_number')} ${string}`
    },

    orderDate: (date) => {
      return asArray ? [`${t('event_formatter.order_date')}`, `${date}`] : `${t('event_formatter.order_date')} ${date}`
    },

    orderModified: (date) => {
      return asArray
        ? [`${t('event_formatter.order_modified')}`, `${date}`]
        : `${t('event_formatter.order_modified')} ${date}`
    },

    seat: (level, section, row, seat) => {
      let output = null
      if (!level && !section && !row && !seat) return ''
      if (!level && !section && !row && seat) output = `${t('event_formatter.seat')} ${seat}`
      if (!level && !section && row && !seat) output = `${t('event_formatter.row')} ${row}`
      if (!level && !section && row && seat)
        output = `${t('event_formatter.row')} ${row}, ${t('event_formatter.seat')} ${seat}`
      if (level && !section && !row && !seat) output = `${t('event_formatter.level')} ${level}`
      if (level && section && !row && !seat)
        output = `${t('event_formatter.level')} ${level}, ${t('event_formatter.section')} ${section}`
      if (level && section && row && !seat)
        output = `${t('event_formatter.level')} ${level}, ${t('event_formatter.section')} ${section}, ${t(
          'event_formatter.row',
        )} ${row}`
      if (level && section && row && seat)
        output = `${t('event_formatter.level')} ${level}, ${t('event_formatter.section')} ${section}, ${t(
          'event_formatter.row',
        )} ${row}, ${t('event_formatter.seat')} ${seat}`
      return asArray ? output.split(' ') : output
    },
  }

  const ticketTypeQuantity = (tickets) => {
    const reducer = tickets.reduce((acc, ticket) => {
      const ticketName = ticket.ticket_type.name
      if (!acc[ticketName]) {
        acc[ticketName] = 0
      }
      acc[ticketName] += 1

      return acc
    }, {})

    return Object.entries(reducer)
      .map(([ticketName, count]) => {
        return `${count} x ${ticketName}`
      })
      .join(', ')
  }

  return {
    generators,
    ticketTypeQuantity,
  }
}
